import React, { useState } from "react";
import "./App.css";

import frFlag from "svg-country-flags/svg/fr.svg";
import deFlag from "svg-country-flags/svg/de.svg";

function App() {
  const [lang, setLang] = useState("fr");

  return (
    <div className="App">
      <header>
        <div className="logo">
          <div className="title">Harm - Avocats</div>
          <div className="ligne" />
          <div className="subtitle">Avocats au Barreau de Paris</div>
        </div>
      </header>
      <section className="hero">
        {lang === "fr" ? (
          <>
            Une équipe franco-allemande et <b>pluridisciplinaire</b>, active
            depuis <b>30&nbsp;ans</b> dans le contentieux civil, commercial et
            pénal, l’expertise judiciaire et spécialisée dans le{" "}
            <b>risque industriel</b> et la <b>responsabilité</b> du fait des
            produits.
          </>
        ) : (
          <>
            Wir sind ein deutsch-französisches Team,{" "}
            <b>interdisziplinär tätig</b> im Zivil, Handels-sowie Strafrecht
            seit dem <b>Jahre 1990</b>. Ein besonderer Fokus unser Tätigkeit
            liegt auf rechtlichen Problemstellungen und Streitigkeiten im
            Bereich <b>industrielle Risiken</b> und <b>Produkthaftung</b>.
            <br />
            <br />
            Wir verfügen hier über langjährige Erfahrung sowohl außergerichtlich
            als auch im Rahmen gerichtlicher Verfahren (selbstständige
            Beweisverfahren, streitige Verfahren).
          </>
        )}
        <div className="flags">
          <img
            src={frFlag}
            className={"flag-item" + (lang === "fr" ? " active" : "")}
            onClick={() => setLang("fr")}
          />
          <img
            src={deFlag}
            className={"flag-item" + (lang === "de" ? " active" : "")}
            onClick={() => setLang("de")}
          />
        </div>
      </section>
      <footer>
        <div className="warning">Site en construction</div>
        <div className="places">Paris - Munich</div>
      </footer>
    </div>
  );
}

export default App;
